import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import Services from "../services/services";
import { Modal, Form as FormAntd, Input, Button, Row, Col, Divider } from 'antd';
import 'antd/dist/antd.css';
import useCollapse from 'react-collapsed';
import 'font-awesome/css/font-awesome.min.css';
import Alert from 'react-bootstrap/Alert';
import { IdleTimerProvider, useIdleTimerContext } from 'react-idle-timer';
import { ReloadOutlined } from '@ant-design/icons';




import "./Lift.css";
const initialState = {
    terminals: [],
    equipments: [],
    filteredEquipements: [],
    currentUserTerminal: null,
    loading: true,
};

const filterInitialState = {
    terminal: "ALL",
    status: 0,
    equipment: "BOTH",
    search: ""
}

const modalInitialState = {
    visible: false,
    equipment: null,
    error: null,
    location: ''
}

export const EquipmentIn = () => {
    const [state, setState] = useState(initialState);
    const [refresh, setRefresh] = useState(Date.now());
    const [filterState, setFilterState] = useState(filterInitialState);
    const [modalState, setModalState] = useState(modalInitialState);
    const [searchValue, setSearchValue] = useState("");
    const [processing, setProcessing] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const currentFilter = useRef(null);
    currentFilter.current = filterState;

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    const renderEquipmentInTable = () => {
        return (
            <>
                <IdleTimerProvider
                    timeout={1000 * 60}
                    onPrompt={onIdleTimerProviderPrompt}
                    onIdle={onIdleTimerProviderIdle}
                    onActive={onIdleTimerProviderActive}
                    onAction={onIdleTimerProviderAction} />

                <Modal style={{ textTransform: 'uppercase', fontSize: '3em' }} width={750} visible={modalState.visible} okText="Yes" cancelText="No" okButtonProps={{ shape: "round", disabled: processing }} cancelButtonProps={{ shape: "round", disabled: processing }} onCancel={handleCancelModal} onOk={handleOnCreateModal} destroyOnClose={true} closable={false}>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        Are you sure you want to unload the equipment {modalState.equipment && modalState.equipment != null ? modalState.equipment.cntr_num : ''} - {modalState.equipment && modalState.equipment != null ? modalState.equipment.line : ''}?
                        <div style={{ paddingTop: '10px' }}>
                            <table style={{ width: '350px', marginLeft: '55px' }}>
                                <tr>
                                    <td style={{ padding: '0px', margin: '0px', width: '50px' }}>
                                        Location:
                                    </td>
                                    <td style={{ padding: '0px', margin: '0px', width: '250px' }}>
                                        <input type="text" style={{ width: '250px', textTransform: "uppercase" }} value={modalState.location}
                                            onChange={handleChangeLocation}></input>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    {
                        modalState.error && modalState.error.length > 0 &&
                        <Alert key={'1'} variant={'danger'}>
                            {modalState.error}
                        </Alert>
                    }

                </Modal>

                <div className="collapsible">
                    <div className="header" {...getToggleProps()}>
                        <div className="border border-5 border-secondary  overflow bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                            <table className="" aria-labelledby="tabelLabel">
                                <thead className="bg-secondary">
                                    <tr>
                                        <th className="fs-4" colSpan="3" id="tabelLabel" style={{ color: "white" }}><i className={isExpanded ? 'fa fa-minus' : 'fa fa-plus'}></i> Equipment IN</th>
                                        <th className="text-end">&nbsp;</th>
                                        <th colSpan="1">
                                        </th>
                                        <th colSpan="3">
                                            <Form.Control
                                                className="fs-7"
                                                size="sm"
                                                type="text"
                                                name="search"
                                                onChange={i => {
                                                    setSearchValue(i.target.value);
                                                }}
                                                onKeyPress={i => {
                                                    if (i.key === "Enter") {
                                                        handleFilters(searchValue, "search");
                                                        i.preventDefault();
                                                        i.stopPropagation();
                                                    }
                                                }}
                                                onClick={i => {
                                                    i.preventDefault();
                                                    i.stopPropagation();
                                                }} />
                                        </th>
                                        <th style={{ width: "10px" }}>
                                            <button
                                                onClick={i => {
                                                    handleFilters(searchValue, "search");
                                                    i.preventDefault();
                                                    i.stopPropagation();
                                                }}>
                                                <i className={'fa fa-search'}></i>
                                            </button>
                                        </th>
                                        <th className="text-end">&nbsp;</th>
                                        <th style={{ width: '50px' }}>
                                            <Button
                                                style={{ display: 'inline-flex', alignItems: 'center' }}
                                                type="primary"
                                                className="rounded"
                                                icon={<ReloadOutlined />}
                                                onClick={(i) => {
                                                    i.preventDefault();
                                                    i.stopPropagation();
                                                    setRefresh(Date.now());
                                                }}>
                                                Refresh
                                            </Button>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>


                    <div {...getCollapseProps()}>
                        <div className="content">
                            {
                                !state.loading &&
                                <div style={{ paddingTop: '0px' }}>
                                    <div className="border border-5 border-secondary  overflow bg-secondary">
                                        <table className="" aria-labelledby="tabelLabel">
                                            {/*<thead className="bg-secondary">*/}
                                            {/*    <tr>*/}
                                            {/*        <th className="fs-4" colSpan="3" id="tabelLabel">Equipment IN</th>*/}
                                            {/*        <th className="text-end">&nbsp;</th>*/}
                                            {/*        <th colSpan="3">*/}
                                            {/*        </th>*/}
                                            {/*        <th colSpan="4"><Form.Control className="fs-7" size="sm" type="text" name="search" value={filterState.search} onChange={i => handleFilters(i, "search")} /></th>*/}
                                            {/*    </tr>*/}
                                            {/*</thead>*/}
                                            <tbody>
                                                {state.filteredEquipements && state.filteredEquipements.map((equip) => (

                                                    <tr className="border border-5 border-secondary bg-white">
                                                        <td className="fs-7 cell-pad text-nowrap">
                                                            <span>{equip.formattedDate}</span><br />
                                                            <span className="fw-bold">{equip.int_num + ' ' + equip.terminal + ' '}</span><span className="fw-bold" style={{ color: 'deepskyblue' }}>{equip.location}</span>
                                                        </td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">
                                                            <span>{equip.cntr_num}</span><br />
                                                            <span>{equip.isO_CODE} ({equip.equip_type})</span>
                                                        </td>
                                                        <td style={{ backgroundColor: equip.color }} className={`fs-5 fw-bold text-center cell-pad`}>{equip.line}</td>
                                                        <td className={`fs-6 fw-bold text-center cell-pad`}>
                                                            <span>{equip.ptI_TYPE}</span>
                                                            <span style={{ color: 'red' }}>{(equip.bodY_DAM == 'T' ? ' BD' : '')}</span>
                                                        </td>

                                                        {/*<td colSpan="2" className={`fs-6 fw-bold text-center cell-pad ${equip.equip_type && !equip.equip_type.includes("40") && equip.equip_attached != "G" ? "bg-green" : ""}`}>{equip.equip_type}</td>*/}
                                                        {/*<td colSpan="2" className={`fs-6 fw-bold text-center cell-pad`}>{equip.equip_type}</td>*/}

                                                        {/*<td className={`fs-6 fw-bold text-center cell-pad ${equip.equip_attached == "G" ? "bg-green" : ""}`}>{equip.equip_attached}</td>*/}
                                                        <td className={`fs-6 fw-bold text-center cell-pad`}>{equip.equip_attached == "G" ? equip.attNum : ""}</td>

                                                        <td colSpan="2" className="fs-6 fw-bold text-center cell-pad">{equip.transport}</td>

                                                        <td className="fs-6 fw-bold text-center cell-pad truck-width">
                                                            <span>#{equip.truck_num}<br></br></span>
                                                            <span>{equip.license_num}</span>
                                                        </td>
                                                        <td colSpan="2" className="fs-7 fw-bold text-center cell-pad">
                                                            <div style={{ color: 'red' }}>{equip.commenT_OH}</div>
                                                            <div style={{ color: 'magenta' }}>{equip.notes}</div>
                                                            <div style={{ color: 'deepskyblue' }}>{equip.putBackDesc}</div>
                                                        </td>
                                                        <td className="cell-pad"><Button type="primary" className="rounded" onClick={i => handleDechargeClick(equip)} style={{ paddingLeft: '0.4em', paddingRight: '0.4em' }}>DISCHARGED</Button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot className="bg-secondary">
                                                <tr className="border border-5 border-secondary">
                                                    <td className="fs-6 fw-bold cell-pad" colSpan="3">
                                                        <div className="row">
                                                            <Form.Label className="col-sm-3">Terminal</Form.Label>
                                                            <div className="col-sm-9">
                                                                <Form.Select value={filterState.terminal} inline="true" className="fs-7 terminal-select" size="sm" onChange={i => handleFilters(i.target.value, "terminal")}>
                                                                    <option value="ALL">All Terminals</option>
                                                                    {state.terminals.map((terminal) => (<option value={terminal}>{terminal}</option>))}
                                                                </Form.Select>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td colSpan="5"></td>
                                                    <td colSpan="4">
                                                        <div className="text-end" onChange={i => handleFilters(i.target.value, "equipment")}>
                                                            <Form.Check name="equipment" value="CONTAINERS" type="radio" inline="true" label="Containers / Reefers" className="fs-7 fw-bold" />
                                                            <Form.Check name="equipment" value="GENSETS" type="radio" inline="true" label="Gensets" className="fs-7 fw-bold" />
                                                            <Form.Check name="equipment" value="BOTH" type="radio" inline="true" label="Both" className="fs-7 fw-bold" defaultChecked={filterState.equipment === "BOTH"} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                </div>
                            }
                            {
                                state.loading == true &&
                                <div className="border border-5 border-secondary  overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                                    <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                                </div>

                            }

                        </div>
                    </div>

                </div>
            </>
        );
    };

    const onIdleTimerProviderPrompt = () => {
    }

    const onIdleTimerProviderActive = (event) => {
    }

    const onIdleTimerProviderAction = (event) => {
    }

    const onIdleTimerProviderIdle = () => {
        setRefresh(Date.now());
    }

    const handleCancelModal = (e) => {
        setModalState({
            ...modalState,
            visible: false,
            equipment: null,
            error: null,
            location: ''
        });
    };

    const handleChangeLocation = (e) => {
        setModalState({
            ...modalState,
            location: e.target.value
        });
    };
    const handleOnCreateModal = (e) => {
        setProcessing(true);
        Services.dechargeEquipment(modalState.equipment.int_num, modalState.equipment.cntr_num, { location: modalState.location, attNum: modalState.equipment.attNum, attLifeNum: modalState.equipment.attLifeNum }, (response) => {
            if (response.status === 200) {
                setModalState(modalInitialState);
                setState({ ...state, loading: true });
                setRefresh(Date.now());
            }
            else {
                setModalState({
                    ...modalState,
                    error: "An error is occured.  Please conatact system administrator.",
                });
            }
            setProcessing(false);

        },
            () => {
                setModalState({
                    ...modalState,
                    error: "An error is occured.  Please conatact system administrator.",
                });
                setProcessing(false);
            });
    };

    const handleDechargeClick = (equip) => {

        setModalState({
            ...modalState,
            visible: true,
            equipment: equip,
            error: null
        });

    };

    const handleFilters = (e, filter) => {
        setFilterState({
            ...filterState,
            [filter]: e
        });
    };

    const render = () => {
        let contents = renderEquipmentInTable()
        return <div>{contents}</div>;
    };

    const filterData = (item, filter) => {
        if (item.int_num && item.int_num.toUpperCase().includes(filter.toUpperCase()))
            return true;

        if (item.cntr_num && item.cntr_num.toUpperCase().includes(filter.toUpperCase()))
            return true;

        if (item.line && item.line.toUpperCase().includes(filter.toUpperCase()))
            return true;

        if (item.equip_type && item.equip_type.toUpperCase().includes(filter.toUpperCase()))
            return true;

        if (item.transport && item.transport.toUpperCase().includes(filter.toUpperCase()))
            return true;

        if (item.truck_num && item.truck_num.toUpperCase().includes(filter.toUpperCase()))
            return true;

        if (item.license_num && item.license_num.toUpperCase().includes(filter.toUpperCase()))
            return true;

        if (item.color && item.color.toUpperCase().includes(filter.toUpperCase()))
            return true;

        return false;
    };

    const filterModel = (equipments, simpleRefresh) => {
        let filtered = equipments && filterState.search ? equipments.filter((i) => filterData(i, filterState.search))
            : equipments ? equipments : [];


        if (filterState.terminal !== "ALL")
            filtered = filtered.filter(i => i.terminal === filterState.terminal);
        if (filterState.equipment !== "BOTH") {
            if (filterState.equipment === "GENSETS")
                filtered = filtered.filter(i => i.equip_type.indexOf("GENSET") > -1);
            else
                filtered = filtered.filter(i => i.equip_type.indexOf("GENSET") == -1);
        }

        if (equipments && !simpleRefresh) {
            setState({
                ...state,
                filteredEquipements: filtered ? filtered.slice(0, 100) : [],
            });
        }
        console.log(filtered);
        console.log(equipments)
        if (simpleRefresh)
            return filtered ? filtered.slice(0, 100) : []
    }

    async function populateViewModel(simpleRefresh) {
        Services.getEquipmentIn((response) => {
            setState({
                equipments: response.data.equipments,
                filteredEquipements: simpleRefresh == true && response.data.equipments ? filterModel(response.data.equipments, simpleRefresh)
                    : response.data.equipments ? response.data.equipments.slice(0, 100) : [],
                terminals: response.data.terminals,
                currentUserTerminal: response.data.currentUserTerminal,
                loading: false,
            });
        });
    }

    useEffect(() => {
        if (state.equipments.length > 0) {
            if (firstLoad) {
                setFirstLoad(false);
                if (state.currentUserTerminal !== null && state.currentUserTerminal !== "") {
                    setFilterState({
                        ...filterState,
                        terminal: state.currentUserTerminal
                    });
                }
            }
        }
    }, [state.equipments]);

    useEffect(() => {
        filterModel(state.equipments);
    }, [filterState]);

    useEffect(() => {
        populateViewModel(true);
    }, [refresh]);

    return render();
};
